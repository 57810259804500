<template>
    <div class="guides-premium-apps-element">
        <div class="guides-img-container">
            <img class="premium-apps-element-img" :src="changeSrc(item.header)" alt="">
        </div>
        <div class="guides-text-btn">
            <h1 class="element-header">{{ changeHeader(item.header) }}</h1>
            <a :href="item.url" target='_blank' class="element-btn">{{ item.btnText }}</a>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        item: Object,
        changeHeader: Function,
        changeSrc: Function
    }
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.premium-apps-element-img {
    margin: 18px 0;
}

.guides-premium-apps-element {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    padding: 18px 0;
    background-color: #141417;
    border-radius: 8px;
    margin-right: 16px;
    transition: .2s all;
}

.guides-premium-apps-element:last-child {
    margin-right: 0;
}

.guides-premium-apps-element:hover {
    background-color: #18191D;
    box-shadow: 0px 7px 12px 0px #1414177A;
}

.element-header {
    color: #FFFFFF99;
    font-size: 16px;
    line-height: 20.16px;
    margin: 12px 0;
}

.element-btn {
    background-color: #4E4BCD;
    padding: 7px 16px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}

.guides-text-btn {
    text-align: center;
    margin: 0 auto;
    height: 40%;
}

.guides-img-container {
    display: flex;
    align-items: center;
    height: 60%;
}

@media only screen and (max-width: 768px) {
    .guides-premium-apps-element {
        margin: 0;
        margin-top: 16px;
    }
}</style>
